header.top-header {
	padding-top: $spacer;
	padding-bottom: $spacer;
	background-color: $brand-primary;
	color: $white;
	a {
		color: $white;
	}
	button {
		color: $white;
		border: none;
		background-color: transparent;
		padding-top:0;
		padding-bottom:0;
	}
	.nav {
		&.nav-top {
			justify-content: flex-end;
			.nav-item {
				padding: 0 ($spacer / 2);
				&:last-child {
					padding-right: 0;
				}
				&:first-child {
					padding-left: 0;
				}
				&.wide {
					flex-grow: 1;
				}
				.link-with-icon {
					cursor: pointer;
					> * {
						display: inline-block;
						vertical-align: middle;
					}
					.icon, .fa {
						margin-right: 3px;
						&.icon-contact {
							@include size(25px, 20px);
						}
					}
					.fa {
						font-size: 1.2em;
					}
				}
				&.dropdown {
					.dropdown-menu {
						margin-top: -3px;
						padding-top: ($spacer * 1.4);
						border-radius: 0;
						border: none;
						background: $brand-primary;
						opacity: 0.9;
						@include media-breakpoint-only(xs) {
							right: 0;
							left: auto;
						}
						.dropdown-item {
							color: $white;
							&:hover, &:focus {
								background-color: lighten($brand-primary, 10%);
							}
						}
					}
					&:hover {
						.dropdown-menu {
							display: block;
						}
					}
				}
			}
		}
	}
}

section.splash-section {
	padding-top: ($spacer * 2);
	padding-bottom: ($spacer * 2);
	.row {
		padding: 0;
		margin: 0;
		align-content: center;
		.splash-item {
			width: 50%;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			.bg-image-logo {
				background-image: url('../images/rqs-logo.png');
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				left: 0; top: 0;
				width: 100%;
				height: 100%;
			}
			&.text-logo {
				font-size: $font-size-h1;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				text-transform: uppercase;
				@include media-breakpoint-only(md) {
					flex-basis: 60%;
					width: 60%;
				}
				@include media-breakpoint-down(sm) {
					font-size: $font-size-h2;
				}
			}
			&.with-logo {
				height: 160px;
				position: relative;
				@include media-breakpoint-only(md) {
					flex-basis: 40%;
					width: 40%;
				}
			}
		}
	}
}
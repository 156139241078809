/* Login Page */
.login-page {
  .main-container {
    padding-top: ($spacer * 4);
    padding-bottom: ($spacer * 4);
  }
  .main {
    width: 440px;
    max-width: 100%;
    .form-login-section {
      width: 290px;
      margin: auto;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .logo-login-wrapper {
      position: relative;
      height: 100px;
      .logo-login {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-image: url('../images/rqs-logo-on-white.png');
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }
    .title-login-wrapper {
      font-size: $font-size-h3;
      @extend .text-center;
      line-height: 1;
      margin-bottom: $spacer;
      padding-bottom: ($spacer / 2);
      border-bottom: 1px solid $gray-lighter;
      margin-top: $spacer;
      padding-top: ($spacer / 2);
    }
    .login-form-wrapper {
      @extend .text-center;
    }
  }
  [name="login-button"] {
    padding-top: .8rem;
    padding-bottom: .8rem;
  }
}

/* Order Page */
.order-form-page {
  section {
    &.order-form-section {
      .form-filter {
        padding: ($spacer / 2) 0;
        .btn {
          text-transform: none;
          font-weight: normal;
          > * {
            vertical-align: middle;
          }
          .icon {
            margin-right: ($spacer / 2);
          }
        }
        .dropdown {
          display: inline-block;
          margin-left: $spacer;
          @include media-breakpoint-down(md) {
            display: none;
          }
          .dropdown-toggle {
            .dropdown-value {
              font-weight: bold;
            }
          }
        }
      }
      .bottom-links {
        @include bottom-links;
      }
    }
  }
}

/* FTP Browser */
.ftp-browser-page {
  section {
    &.nav-controls {
      background-color: $gray-dark;
      color: $white;
      margin-left: -($spacer * 1.5);
      margin-right: -($spacer * 1.5);
      > .container {
        padding: ($spacer / 2) ($spacer * 1.5);
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
        }
        > div {
          display: block;
          @include media-breakpoint-up(lg) {
            &:nth-child(n+2) {
              margin-left: $spacer;
            }
          }
          @include media-breakpoint-down(md) {
            &:nth-child(n+2) {
              margin-top: $spacer;
            }
            .btn {
              width: 100%;
            }
          }
          &.other-controls {
            > div {
              display: block;
              vertical-align: middle;
              label {
                margin-bottom: 0;
              }
              @include media-breakpoint-down(md) {
                &:nth-child(n+2) {
                  margin-top: $spacer;
                }
              }
            }
            @include media-breakpoint-up(lg) {
              text-align: right;
              flex-grow: 1;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              justify-content: flex-end;
              > div {
                &:nth-child(n+2) {
                  margin-left: $spacer;
                }
              }
            }
          }
        }
      }
    }
    &.catalog-table-section {
      .table {
        @extend .table-block;
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
        tr {
          td {
            &.filename {
              a {
                font-weight: bold;
              }
              &.with-image {
                .image-wrapper {
                  width: 80px;
                  margin: auto;
                  img {
                    width: 100%;
                  }
                }
              }
            }
            @include media-breakpoint-up(md) {
              &.checkbox {
                text-align: right;
              }
              &.download {
                max-width: 24px;
              }
              &.filesize {
                max-width: 100px;
              }
              &.date {
                max-width: 125px;
              }
              &.filename {
                &.with-image {
                  vertical-align: center;
                  .image-wrapper {
                    display: inline-block;
                    width: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.categories-page {
  section {
    &.nav-controls {
      background-color: $gray-dark;
      color: $white;
      margin-left: -($spacer * 1.5);
      margin-right: -($spacer * 1.5);
      > .container {
        padding: ($spacer / 2) ($spacer * 1.5);
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
        }
        > div {
          display: block;
          @include media-breakpoint-up(lg) {
            &:nth-child(n+2) {
              margin-left: $spacer;
            }
          }
          @include media-breakpoint-down(md) {
            &:nth-child(n+2) {
              margin-top: $spacer;
            }
            .btn {
              width: 100%;
            }
          }
          &.other-controls {
            > div {
              display: block;
              vertical-align: middle;
              label {
                margin-bottom: 0;
              }
              @include media-breakpoint-down(md) {
                &:nth-child(n+2) {
                  margin-top: $spacer;
                }
              }
            }
            @include media-breakpoint-up(lg) {
              text-align: right;
              flex-grow: 1;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              justify-content: flex-end;
              > div {
                &:nth-child(n+2) {
                  margin-left: $spacer;
                }
              }
            }
          }
        }
      }
    }
    &.catalog-table-section {
      .table {
        @extend .table-block;
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
        tr {
          td {
            &.filename {
              a {
                font-weight: bold;
              }
              &.with-image {
                .image-wrapper {
                  width: 80px;
                  margin: auto;
                  img {
                    width: 100%;
                  }
                }
              }
            }
            @include media-breakpoint-up(md) {
              &.checkbox {
                text-align: right;
              }
              &.download {
                max-width: 24px;
              }
              &.filesize {
                max-width: 100px;
              }
              &.date {
                max-width: 125px;
              }
              &.filename {
                &.with-image {
                  vertical-align: center;
                  .image-wrapper {
                    display: inline-block;
                    width: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* all pages */
section {
  &.nav-controls {
    background-color: $gray-dark;
    color: $white;
    margin-left: -($spacer * 1.5);
    margin-right: -($spacer * 1.5);
    > .container {
      padding: ($spacer / 2) ($spacer * 1.5);
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }
      > div {
        display: block;
        @include media-breakpoint-up(lg) {
          &:nth-child(n+2) {
            margin-left: $spacer;
          }
        }
        @include media-breakpoint-down(md) {
          &:nth-child(n+2) {
            margin-top: $spacer;
          }
          .btn {
            width: 100%;
          }
        }
        &.other-controls {
          > div {
            display: block;
            vertical-align: middle;
            label {
              margin-bottom: 0;
            }
            @include media-breakpoint-down(md) {
              &:nth-child(n+2) {
                margin-top: $spacer;
              }
            }
          }
          @include media-breakpoint-up(lg) {
            text-align: right;
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: flex-end;
            > div {
              &:nth-child(n+2) {
                margin-left: $spacer;
              }
            }
          }
        }
      }
    }
  }
  &.table-section {
    .table {
      @extend .table-block;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
      tr {
        td {
          &.with-icon {
            width: 2rem;
            i {
              vertical-align: middle;
            }
          }
          &.filename {
            a {
              font-weight: bold;
            }
            &.with-image {
              .image-wrapper {
                width: 80px;
                margin: auto;
                img {
                  width: 100%;
                }
              }
            }
            @include media-breakpoint-down(sm) {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              &.with-image .image-wrapper {
                margin: 0;
              }
            }
          }
          @include media-breakpoint-up(md) {
            &.checkbox {
              max-width: 42px;
              text-align: right;
            }
            &.download {
              max-width: 24px;
            }
            &.filesize {
              max-width: 100px;
            }
            &.date {
              max-width: 125px;
            }
            &.filename {
              &.with-image {
                vertical-align: center;
                .image-wrapper {
                  display: inline-block;
                  width: 80px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.section-breadcrumbs {
    .breadcrumbs {
      font-weight: bold;
      li {
        text-decoration: none;
        display: inline-block;
        :after {
          padding: 0 5px;
          color: $gray-light;;
          content: ">";
        }
      }
      a {
        color: $gray-light;
      }
      > span:last-child {
        color: $body-color;
      }
      > * {
        vertical-align: middle;
        padding: 0;
      }
      ul {
        margin-bottom: 0;
      }
    }
    .controls-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        justify-content: flex-start;
        margin-top: $spacer;
        .cart-info-block {
          flex-grow: 1;
          justify-content: flex-end;
        }
      }
      @include media-breakpoint-down(sm) {
        display: block;
        .cart-info-block {
          display: inline-block;
        }
        .icon-folder-structure {
          height: 32px;
        }
        .icon-folder-structure,
        .search-products,
        .cart-info-block {
          vertical-align: middle;
          margin-bottom: $spacer;
        }
      }
      @include media-breakpoint-down(xs) {
        .icon-folder-structure {
          position: absolute;
        }
        .search-products {
          width: 100%;
          padding-left: 32px;
          input {
            width: 100%;
          }
        }
      }
    }
    .icon-folder-structure {
      margin-right: ($spacer / 2);
    }
    .search-products {
      @extend .search-form;
      margin-right: ($spacer / 2);
      display: inline-block;
    }
    .cart-info-block {
      display: flex;
      align-items: center;
      padding-right: 20px;
      position: relative;
      cursor: pointer;
      .dropdown-menu {
        cursor: default;
        .pack-remove-link {
          margin-left: 10px;
          padding: 5px;
          color: red;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .icon {
        margin-right: ($spacer / 2);
      }
      .minicart-summary {
        position: relative;
        &:after {
          @extend .fa;
          content: "\f078";
          position: absolute;
          right: -20px;
          top: 3px;
        }
      }

    }
  }
}

.table-wrapper {
  margin-left: -($spacer * 1.5);
  margin-right: -($spacer * 1.5);
  width: auto;
  table.products-table {
    tbody, thead, tfoot {
      tr > :first-child {
        padding-left: ($spacer * 1.5);
      }
      tr > :last-child {
        padding-right: ($spacer * 1.5);
      }
    }
    thead {
      .product-buttons {
        width: 80px;
      }
      background-color: $gray-dark;
      color: $white;
      tr > * {
        vertical-align: middle;
      }

    }
    thead, tbody {
      .combination {
        width: 92px;
        padding: 0 12px 0 0;
        position: relative;
        .combination-name {
          border: 1px solid;
          padding: 4px 11px;
          width: 84px;
          display: inline-block;
          text-align: center;
          &.seeds {
            font-size: 15px;
          }
        }
        .info-type .combination-name {
          width: 82px;
          background-color: #555;
          color: #fff;
          border: none;
          padding: 6px;
          font-weight: bold;
          margin-bottom: 12px;
        }
      }
      .product-total {
        width: 100px;
      }

    }
    tbody {
      .add-product-button {
        color: white;
        padding: 5px 10px;
        height: 35px;
        width: 35px;
		border-radius: 3px;
		&:hover {
			cursor: pointer;
		}
      }
      .product-button-cart {
        background-image: url(../images/icon-shopping-cart-white.png);
        background-repeat: no-repeat;
        background-position: 7px 9px;
        background-size: 20px;
      }
      .product-name {
        vertical-align: middle;
        .product-info {
          display: flex;
          align-items: center;
          .product-image {
            width: 80px;
            padding-right: ($spacer / 2);
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .combination {
        vertical-align: bottom;
        padding-top: $spacer;
        .combination-value {
          input {
            border: 1px solid $gray-light;
            padding: 7px 11px;
            width: 82px;
            background: transparent;
            line-height: 1;
            border-radius: 0;
            text-align: center;
            &:disabled {
              border-color: $red;
              cursor: not-allowed;
              opacity: 0.9;
              padding-left: 1px;
              padding-right: 1px;
              &::placeholder {
                color: $red;
                font-size: 12px;
              }
            }
            &:active, &:focus {
              border-color: $blue-light;
            }
            &.jsValueSet {
              border-color: $green-light;
			  background-color: #bdf7be;
            }
            &.jsValueError {
              border-color: #CB4154;
              background-color: #CB4154;
            }
          }
        }
        .sub-price {
          display: block;
          text-align: center;
        }
      }
      .product-total {
        padding-top: $spacer;
        vertical-align: middle;
        font-weight: bold;
        white-space: nowrap;
        span {
          display: inline-flex;
          height: 34px;
          align-items: center;
        }
      }
    }
    tr:last-child {
      td {
        border-bottom: $table-border-width solid $table-border-color;
      }
    }
  }
}

.cart-price-total {
  font-weight: bold;
  font-size: $font-size-lg;
}
.title-container {
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: $spacer;
  margin-top: $spacer;
  h1 {
	font-size: $font-size-h3;
	color: $gray-light;
	margin-top: (2 * $spacer);
  }
}
.sidebar-block {
  padding: ($spacer * 1.5);
  hr {
    margin-top: ($spacer / 2);
    margin-bottom: ($spacer / 2);
  }
}
.dropdown-item:active {
  b {
    color: white;
  }
}
.order-container {
  display: flex;
  justify-content: space-between;
  label {
    display: block;
  }
  #comment {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    resize: none;
    height: 71px;
    line-height: 1.6;
    overflow-y: hidden;
  }
  #comment:focus {
    border-color: #555;
    outline: none;
  }
}

.detail-user-view {
  &.table tr td {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 0.75rem;
  }
}
.discount-color {
  color: red;
}

.warning-message {
  display: inline-block;
  background-color: #ffc107;
  color: #212529;
  padding: 10px;
  border-radius: 5px;
}

.discount-block {
  color: grey;
  font-size: 75%;
  .total-old-price {
    text-decoration: line-through;
  }
  .discount-percent {
    color: red;
  }
}

#cart-submit .discount-block {
  position: relative;
  top: 6px;
}
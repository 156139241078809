@mixin bottom-links() {
	text-align: right;
	margin-top: ($spacer / 2);
	> * {
		@include media-breakpoint-up(md) {
			&:nth-child(n+2) {
				margin-left: $spacer;
			}
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			margin-left: 0;
			margin-top: $spacer;
		}
	}
}
.bottom-links {
	@include bottom-links();
}
.btn {
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	cursor: pointer;
	white-space: normal;
	&.no-uppercase {
		text-transform: none;
	}
}
.btn-info {
	@include button-variant($white, $blue-light, $blue-light);
}
.btn-white {
	@include button-variant($gray-dark, $white, $gray-dark);
}
.btn-gray-dark {
	@include button-variant($white, $gray-dark, $white);
}
.btn-light-green {
	@include button-variant($white, $green-light, $green-light);
}
.btn-icon-shopping-cart-white {
	background-repeat: no-repeat;
	background-position: 90% 9px;
	background-size: 20px;
	padding-right: 42px;
	&, &:active {
		background-image: url(../images/icon-shopping-cart-white.png);
	}
}
.btn-with-icon {
	.icon {
		&:last-child {
			margin-left: $spacer;
		}
		&:first-child {
			margin-right: $spacer;
		}
	}
	>* {
		vertical-align: middle;
	}
}
/* Typography */
.text-bold {
	font-weight: $font-weight-bold;
}
.text-light {
	font-weight: 300;
}
.text-green {
	color: $green;
}
.text-gray-light {
	color: $gray-light;
}
.bg-lightgray {
	background-color: $gray-lightest;
}

/* Fonts */
@font-face {
	font-family: 'RobotoCondensed';
	src: url("../fonts/RobotoCondensed-Regular.ttf")  format('truetype');	
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url("../fonts/RobotoCondensed-Bold.ttf")  format('truetype');	
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url("../fonts/RobotoCondensed-Italic.ttf")  format('truetype');	
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url("../fonts/RobotoCondensed-Light.ttf")  format('truetype');	
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url("../fonts/RobotoCondensed-LightItalic.ttf")  format('truetype');	
	font-weight: lighter;
	font-style: italic;
}

/* Icons */
.icon {
	display: inline-block;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	@include size(18px);
	&.icon-shopping-cart {
		background-image: url('../images/icon-shopping-cart.png');
	}
	&.icon-shopping-cart-white {
		background-image: url('../images/icon-shopping-cart-white.png');
	}
	&.icon-folder-structure {
		background-image: url('../images/icon-folder-structure.png');
	}
	&.icon-search {
		background-image: url('../images/icon-search.png');
	}
	&.icon-arrow-back {
		background-image: url('../images/icon-arrow-back.png');
	}
	&.icon-close-x {
		background-image: url('../images/icon-close-x.png');
	}
	&.icon-download {
		background-image: url('../images/icon-download.png');
	}
	&.icon-download-white {
		background-image: url('../images/icon-download-white.png');
	}
	&.icon-folder {
		background-image: url('../images/icon-folder.png');
	}
	&.icon-contact {
		background-image: url('../images/icon-contact.png');
	}
}

/* Modals */
.modal {
	.modal-dialog {
		&:before {
			content: "";
			top: -6px;
			bottom: -6px;
			right: -6px;
			left: -6px;
			z-index: 9;
			background-color: #fff;
			transform: skew(2deg, 2deg);
			position: absolute;
		}

		.modal-content {
			border-radius: 0;
			box-shadow: none;
			border: 0;
			z-index: 10;
			background-color: transparent;
			.modal-body {
				padding: 30px;
			}
		}
		.close {
			opacity: 1;
		}
	}
	&.show {
		.modal-dialog {
			top: 50%;
			transform: translateY(-50%);
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}


/* Common */
.main {
	background-color: $main-bg;
	padding: ($spacer * 1.5);
	margin: auto;
	position: relative;
	margin-bottom: ($spacer * 4);
	&:before,
		&:after {
		content: '';
		background-color: $main-bg;
		position: absolute;
		left: 0;
		height: 50px;
		width: 100%;
		z-index: -1;
	}
	&:before {
		top: -12px;
		transform: skewY(-2deg);
		@include media-breakpoint-up(md) {
			top: -20px;
		}
	}
	&:after {
		bottom: -13px;
		transform: skewY(2deg);
		@include media-breakpoint-up(md) {
			bottom: -20px;
		}
	}
}

section {
	padding-top: $spacer;
	padding-bottom: $spacer;
	&.no-bottom-padding {
		padding-bottom: 0;
	}
	&.no-top-padding {
		padding-top: 0;
	}
}

.table-block {
	tr td.with-icon {
		padding: 5px;
		vertical-align: middle;
		i {
			@include size(32px);
			vertical-align: middle;
		}
	}
	@include media-breakpoint-down(sm) {
		display: block;
		thead, tbody, tfoot,
		tr, td, th {
			&.with-icon,
			&.filename,
			&.name {
				display: inline-block;
			}
		}
		tr td, tr th {
			border: none;
		}
		tr {
			padding: ($spacer / 2) 0;
			&:nth-child(n+2) {
				border-top: 1px solid $table-border-color;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		td, th {
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}
}
.table {
	tr td, tr th {
		vertical-align: middle;
		padding: 0.5rem 0;
	}
	th.go-back {
		max-width: 100px;
	}
}
.hidden {
	display: none;
}

.loading,.loading>td,.loading>th,.nav li.loading.active>a,.pagination li.loading,.pagination>li.active.loading>a,.pager>li.loading>a{
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
	background-size: 40px 40px;
	animation: 2s linear 0s normal none infinite progress-bar-stripes;
	-webkit-animation: progress-bar-stripes 2s linear infinite;
}
.btn.btn-default.loading,input[type="text"].loading,select.loading,textarea.loading,.well.loading,.list-group-item.loading,.pagination>li.active.loading>a,.pager>li.loading>a{
	background-image: linear-gradient(45deg, rgba(235, 235, 235, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(235, 235, 235, 0.15) 50%, rgba(235, 235, 235, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}
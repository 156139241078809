// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-darker:               #1d201f !default;
$gray-dark:                 #555555 !default;
$gray:                      #646967 !default;
$gray2:                     #b5b9b8 !default;
$gray-light:                #9ea5a3 !default;
$gray-lighter:              #eaeaeb !default;
$gray-lightest:             #f7f8f9 !default;

$green:                     #237232 !default;
$green-light:               #54c056 !default;
$blue-light:                #00a2df !default;
$red:                       #fe485c !default;

$brand-primary:             $green !default;
$brand-primary-light:       $green-light !default;
$brand-success:             #00c100 !default;
$brand-info:                #554baa !default;
$brand-warning:             #FA621C !default;
$brand-danger:              #c10000 !default;
$brand-inverse:             $gray-dark !default;

$dark-gray-border:          #3f4342;
$footer-bg-color:           #f3f5f8;
$footer-color:              #adb6b3;
$input-placeholder-color:   #c8cacd;
$black-color:               #000000;
$white-color:               #ffffff;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               true !default;
$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: ($spacer-x * 5),
    y: ($spacer-y * 5)
  )
) !default;
$border-width: 1px !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:    $gray-lighter !default;
$body-color: $gray-dark !default;
$main-bg:    #fff;

// Links
//
// Style anchor elements.

$link-color:            $gray-dark !default;
$link-decoration:       none !default;
$link-hover-color:      $gray !default;
$link-hover-decoration: none !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:      12 !default;
$grid-gutter-width: 40px !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'RobotoCondensed', Arial, Tahoma;


// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px;
$font-size-btn: 0.875rem !default; // 14px

$font-size-base: 1rem !default; // 18px, body copy
$font-size-lg: 1.25rem !default; // 20px
$font-size-sm: 0.875rem !default; // 14px

$line-height-base: 1.5 !default;

$font-size-h1: 3rem !default; // 48px
$font-size-h2: 2.25rem !default; // 36px
$font-size-h3: 1.875rem !default; // 30px
$font-size-h4: 1.75rem !default; // 28px
$font-size-h5: 1.25rem !default; // 20px
$font-size-h6: 1rem !default; // 16px

$btn-border-radius: 3px !default;
.text-input {
	padding: 3px 6px;
	border: 1px solid $gray-lighter;
	background-color: $gray-lightest;
	border-radius: $btn-border-radius;
	&::placeholder {
		color: $gray-light;
	}
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="file"],
input[type="url"] {
	@extend .text-input;
}

input[type="text"],
input[type="email"],
input[type="password"] {
	padding: ($spacer / 2) $spacer;
}

.has-error {
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		border-color: $brand-warning;
	}
	.help-block-error {
		color: $brand-warning;
	}
}
.help-block-error {
	margin-bottom: 0;
}

.input-box {
	label {
		display: block;
		width: 100%;
		margin-bottom: ($spacer / 4);
	}
	input, select {
		display: block;
		width: 100%;
	}
	&:not(:first-child) {
		margin-top: $spacer;
	}
}

.search-form {
	input[type="text"] {
		@extend .text-input;
		padding-left: 24px;
		width: 160px;
		background-image: url('../images/icon-search.png');
		background-position: 4px 50%;
		background-repeat: no-repeat;
		background-size: 18px;
		background-color: transparent;
	}
}

.form-group {
	margin-bottom: ($spacer / 2);
	.control-label {
		font-weight: 600;
		display: block;
	}
	input[type="file"] {
		appearance: none;
		-webkit-appearance: none;
		background: #fff;
		border: none;
		padding: 0;
	}
}